<template>
  <div>
    <v-card class="text-center pa-1">
      <v-card-title class="justify-center display-1 mb-2">Käyttäjätilin varmennus</v-card-title>

      <v-card-text>
        <h3 v-if="msg" :style="{ color }">{{ msg }}</h3>

        <div v-else>
          <h3 class="mb-1">Odota...</h3>
          <v-progress-circular
            class="justify-center"
            :size="60"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>

        <div v-if="showLink" class="mt-5">
          <router-link to="/login">Kirjaudu sisään</router-link>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import axiosMethods from "../../mixins/axios";

export default {
  title: "Käyttäjätilin varmennus",

  mixins: [mixins],

  data() {
    return {
      msg: "",
      color: "#333",
      showLink: false,
    };
  },

  async created() {
    if (this.$store.getters.isLoggedIn) {
      this.$router.push("/overview");
      return;
    }

    try {
      const { data } = await axiosMethods.get("/api/v1/user/confirm/" + this.$route.params.id);

      this.color = "#00ba86";
      this.msg = data.msg;
      this.showLink = true;
    } catch (err) {
      this.color = "#ef476f";
      this.msg = "Käyttäjää ei voitu varmentaa";
    }
  },
};
</script>

<style scoped></style>
